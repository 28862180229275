
import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import Label from 'components/Label';
import { Stack } from '@material-ui/core';

const FormSelectInput = (field: any) => {
    const lableDisplay = field.label ? <Typography variant='body1' width={"100%"}>{field.label}</Typography> : null;
    return (
        <Stack width="100%">
            {lableDisplay}
            <TextField
                select
                {...field}
                label={lableDisplay ? 'Select' : field.title}
                SelectProps={{ native: true }}
                disabled={field.disabled || false}
            >
                <option value="" />
                {
                    field.options.map((option: any, idx: number) => {
                        if (typeof option === 'object') {
                            return <option key={idx} value={option.value}>{option.label}</option>
                        } else if (typeof option === 'string') {
                            return <option key={idx} value={option}>{option}</option>
                        }
                    })
                }
            </TextField>
        </Stack>
    )
}

export default FormSelectInput;