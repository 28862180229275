
import React from 'react';
import { Stack, TextField, Typography } from '@material-ui/core';

const FormTextInput = (field: any) => {

    const lableDisplay = field.label ? <Typography variant='body1' width={"100%"}>{field.label}</Typography> : null;
    return (
        <Stack width={"100%"}>
            {lableDisplay}
            <TextField
                {...field}
                label={field.title}
                fullWidth
                disabled={field.disabled || false}
                type={field.textType || 'text'}
            />
        </Stack>
    )
}

export default FormTextInput;