import React, { useContext } from 'react';
import { DynamicFormContext, Section } from '../form-editor/FormEditorProvider';
import BackArrow from '@material-ui/icons/ArrowBack';
import { Button, StepLabel, Stepper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Step } from '@material-ui/core';

const SectionSelector = ({canNavigate = false, onBack}: {canNavigate?: boolean, onBack?: ()=>void}) => {
    const context = useContext(DynamicFormContext);
    const { selectSection, formState } = context!;
    const selectedSectionIndex = formState.sections.findIndex((section: any) => section.selected);

    return <>
        <Stepper activeStep={selectedSectionIndex} alternativeLabel>
            {formState.sections.filter(
                (section: Section) => !section.hidden
            ).map((section: Section, index: number) => {
                return <Step
                    key={index}
                    onClick={
                        () => canNavigate ? selectSection(section.id): null
                    }
                >
                    <StepLabel>{section.title}</StepLabel>
                </Step>
            })}
        </Stepper>
        {
            selectedSectionIndex > 0 && !canNavigate
            ? <Button
                onClick={
                    () => {
                        selectSection(formState.sections[selectedSectionIndex - 1].id);
                        if (onBack) {
                            onBack();
                        }
                    }
                }
            ><BackArrow /></Button>
            : <></>
        }
    </>;
}

export default SectionSelector;