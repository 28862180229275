import { UserForm } from "components/_dashboard/users";
import BackArrow from '@material-ui/icons/ArrowBack';
import Page from "components/Page";
import { Container, Card, CardContent, CardHeader, Typography, Switch, Stack } from "@material-ui/core";
import apiClient from "services/apiClient";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { UsersQueries } from "../users/queries";
import LoadingScreen from "components/LoadingScreen";
import { useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import { Button } from "@material-ui/core";
import ClientSelector from "components/ClientSelector";
import PortalUserAccess from "components/_dashboard/portal_users/PortalUserAccess";

const PortalUserView = () => {
    const location = useLocation();
    const nav = useNavigate();
    const id = location.pathname.split('/')[location.pathname.split('/').length - 1];
    const [userEditMode, setUserEditMode] = useState(false);
    const [userAccessEditMode, setUserAcccessEditMode] = useState(false);

    const { data, isLoading } = useQuery<any, Error>(
        [`user-data-${id}`],
        () => UsersQueries.fetchUser({ id, trashed: false })
    );

    const { data: portalAccess, isLoading: accessIsLoading } = useQuery<any, Error>(
        [`user-portal-access-${id}`],
        () => UsersQueries.fetchUserPortalAccess({
            id,
            trashed: false
        })
    );

    const onSaveAccess = async (data: any) => {
        return apiClient.post(`/api/users/${id}/portal_access`, data);
    }

    if (isLoading) {
        return <LoadingScreen />;
    }

    const { data: record } = data;

    return (
        <Page title="Portal User">
            <Container>
                <Button onClick={() => { nav(-1); }}><BackArrow /></Button>
                <Stack direction="column" spacing={2}>
                    <Card>
                        <CardHeader
                            action={
                                !record?.deleted_at
                                    ? <FormControlLabel
                                        control={
                                            <Switch
                                                checked={userEditMode}
                                                onChange={
                                                    () => { setUserEditMode(!userEditMode) }
                                                }
                                                name="userEditMode"
                                            />
                                        }
                                        label="Edit Mode"
                                    /> : <></>
                            }
                            title={<Typography component="h1" variant="h3">User Details</Typography>}
                        />
                        <CardContent>
                            <UserForm
                                data={record}
                                edit={userEditMode || id === 'new'}
                                isPortalUser={true}
                            />
                        </CardContent>
                    </Card>
                    {
                        record?.id && !accessIsLoading
                            ? <Card>
                                <CardHeader
                                    action={
                                        !record?.deleted_at
                                            ? <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={userAccessEditMode}
                                                        onChange={
                                                            () => { setUserAcccessEditMode(!userAccessEditMode) }
                                                        }
                                                        name="userAccessEditMode"
                                                    />
                                                }
                                                label="Edit Mode"
                                            /> : <></>
                                    }
                                    title={<Typography component="h1" variant="h3">User Details</Typography>}
                                />
                                <CardContent>
                                    <PortalUserAccess edit={userAccessEditMode} data={portalAccess.data} onSave={onSaveAccess}/>
                                </CardContent>
                            </Card> : <></>
                    }
                </Stack>
            </Container>
        </Page>
    );
}

export default PortalUserView;