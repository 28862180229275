import { OnboardingRequest } from "../../../../@types/onboarding_request";
import apiClient from "services/apiClient";

interface IndexInterface {
    offset: number
    nextPageNumber: number
    orderBy: string
    sort: string
    text?: string
    additionalFilters?: Array<any>
}

export interface IndexResponse {
    data: Array<OnboardingRequest>,
    total: number
}
const index = async (params: IndexInterface): Promise<IndexResponse> => {
    const { offset, nextPageNumber } = params;
    
    let requestUrl = `/api/onboarding_requests`;

    // Handle additional filters
    if (params.additionalFilters) {
        params.additionalFilters.forEach(({ name, value }) => {
            requestUrl += `&${name}=${value}`;
        });
    }

    // Hanlde base filtering
    requestUrl += `?limit=${offset}&page=${nextPageNumber == 0 ? 1 : nextPageNumber}`;

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data.data,
        total: response.data.data.total
    };
};

interface GetInterface {
    id: string
}
const get = async ({id}: GetInterface): Promise<OnboardingRequest|undefined> => {
    if(id == 'new') {
        return undefined;
    }
    const response = await apiClient.get(`/api/onboarding_requests/${id}`);
    return response.data;
}

export default {
    index,
    get
}