import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import apiClient from 'services/apiClient';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

type InitialValues = {
	email: string
	password: string
	password_confirmation: string
	token: string
	afterSubmit: string
};

type ResetPasswordFormProps = {
	onSave: VoidFunction;
};

export default function SetNewPasswordForm({ onSave }: ResetPasswordFormProps) {
	const { token } = useParams<any>();
	const isMountedRef = useIsMountedRef()

	const ResetPasswordSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required')
	});

	const formik = useFormik<InitialValues>({
		initialValues: {
			email: '',
			password: '',
			password_confirmation: '',
			afterSubmit: '',
			token: ''
		},
		validationSchema: ResetPasswordSchema,
		onSubmit: async (values, { setErrors, setSubmitting }) => {
			if(values.password != values.password_confirmation){
				let errMessage = "Passwords do not match. Please verify that the password and confirm password match and try again.";
				if (isMountedRef.current) {
					setErrors({ afterSubmit: errMessage });
					setSubmitting(false);
				};
				return;
			}
			let requestUrl = `api/user/reset/resetPassword`;
			const { email, password, password_confirmation } = values;
			apiClient.post(requestUrl, { email, password, password_confirmation, token }).then(res => {
				if (isMountedRef.current) {
					onSave();
					setSubmitting(false);
				}
			})
			.catch(error => {
				let errMessage = "Error: Could not set new password";
				if(error.response?.data?.message && error.response?.data?.message != ''){
					errMessage = error.response?.data?.message;
				}
				if (isMountedRef.current) {
					setErrors({ afterSubmit: errMessage });
					setSubmitting(false);
				};
			});
		}
	});

	const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

	return (
		<FormikProvider value={formik} >
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					{errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

					<TextField
						fullWidth
						{...getFieldProps('email')}
						type="email"
						label="Email address"
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
					/>
					<TextField
						fullWidth
						{...getFieldProps('password')}
						type="password"
						label="Password"
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
					/>
					<TextField
						fullWidth
						{...getFieldProps('password_confirmation')}
						type="password"
						label="Confirm Password"
						error={Boolean(touched.password_confirmation && errors.password_confirmation)}
						helperText={touched.password_confirmation && errors.password_confirmation}
					/>

					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						loading={isSubmitting}
					>
						Reset Password
					</LoadingButton>
				</Stack>
			</Form>
		</FormikProvider >
	);
}
