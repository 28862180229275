import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import viewOutline from '@iconify/icons-eva/file-text-outline';
// import editOutline from '@iconify/icons-eva/edit-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
	Box,
	Menu,
	Card,
	Table,
	Button,
	Divider,
	MenuItem,
	TableRow,
	TableBody,
	TableCell,
	TableHead,
	Typography,
	CardHeader,
	TableContainer,
	TablePagination,
	Link,
	FormControlLabel,
	Switch
} from '@material-ui/core';
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { User } from '../../../@types/user';
import SortingSelectingHead from 'pages/components-overview/material-ui/table/sorting-selecting/SortingSelectingHead';
import { PATH_DASHBOARD } from 'routes/paths';
import { red } from '@material-ui/core/colors';
import HasPermission from 'services/permissionsService';

interface MoreMnuButtonInterface {
	id: string
	trashed: boolean
	onDelete: (id: string) => void
	onReActivate: (id: string) => void
}
function MoreMenuButton({ id, trashed, onDelete, onReActivate }: MoreMnuButtonInterface) {
	const menuRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	let link = `${PATH_DASHBOARD.settings.user_management}/${id}`;
	if (trashed) {
		link = `${PATH_DASHBOARD.settings.user_management}/trashed/${id}`;
	}

	return (
		<>
			<>
				<MIconButton ref={menuRef} size="large" onClick={handleOpen}>
					<Icon icon={moreVerticalFill} width={20} height={20} />
				</MIconButton>
			</>

			<Menu
				open={open}
				anchorEl={menuRef.current}
				onClose={handleClose}
				PaperProps={{
					sx: { width: 200, maxWidth: '100%' }
				}}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Link underline="none" variant="subtitle2" component={RouterLink} to={link}>
					<MenuItem>
						<Icon icon={viewOutline} width={20} height={20} />
						<Typography variant="body2" sx={{ ml: 2 }}>
							View
						</Typography>
					</MenuItem>
				</Link>
				<Divider />
				{
					trashed
					&& HasPermission({permissions: ["can:userManagementBasicCreate"]})
					? <MenuItem sx={{ color: 'error.main' }} onClick={() => { onReActivate(id) }}>
						<Icon icon={trash2Outline} width={20} height={20} />
						<Typography variant="body2" sx={{ ml: 2 }}>
							Re-Activate
						</Typography>
					</MenuItem>
					: <></>
				}
				{
					HasPermission({permissions: ["can:userManagementBasicDelete"]})
					&& !trashed
					? <MenuItem sx={{ color: 'error.main' }} onClick={() => { onDelete(id) }}>
						<Icon icon={trash2Outline} width={20} height={20} />
						<Typography variant="body2" sx={{ ml: 2 }}>
							Disable
						</Typography>
					</MenuItem>
					: <></>
				}
			</Menu>
		</>
	);
}

interface UsersList {
	data: Array<User>
	supportUser?: User
	pageNumber: number
	offset: number
	total: number
	orderBy: string
	sort: "desc" | "asc"
	onSupportChange: (value: Boolean) => void
	onPaginate: (pageNumber: number) => void
	onRowsPerPage: (rowsPerPage: number) => void
	onRequestSort: (field: string) => void
	onDelete: (id: string) => void
	onReActivate: (id: string) => void
}
export default function UserCustomersList(props: UsersList) {
	const { supportUser, data, pageNumber, offset, total, orderBy, sort, onPaginate, onRowsPerPage, onRequestSort, onDelete, onReActivate, onSupportChange } = props;
	const theme = useTheme();

	const actions = [];
	if(HasPermission({permissions: ["can:userManagementAdvancedEnableSupportUser"]})){
		actions.push(<FormControlLabel
			control={
				<Switch
					checked={supportUser ? !supportUser.deleted_at : false}
					onChange={(e) => {
						onSupportChange(!!e.target.value)
					}}
					name="supportUser"
				/>
			}
			label="Support User"
			/>
		);
	}
	if(HasPermission({permissions: ["can:userManagementBasicUpdate"]})){
		actions.push(<Link
			underline="none"
			variant="subtitle2"
			component={RouterLink}
			to={`${PATH_DASHBOARD.settings.user_management}/new`}
			>New User</Link>
		);
	}
	return (
		<Card>
			<CardHeader
				title="Users List" sx={{ mb: 3 }}
				action={actions}
			/>
			<Scrollbar>
				<TableContainer sx={{ minWidth: 720 }}>
					<Table>
						<SortingSelectingHead
							selectable={false}
							orderBy={orderBy}
							rowCount={total}
							numSelected={0}
							onRequestSort={(property) => onRequestSort(property)}
							onSelectAllClick={(checked) => { }}
							order={sort}
							headLabel={[
								{
									id: 'id',
									numeric: true,
									label: 'User ID'
								},
								{
									id: 'name',
									numeric: false,
									label: 'Name'
								},
								{
									id: 'email',
									numeric: false,
									label: 'Email'
								},
								{
									id: 'created_on',
									numeric: false,
									label: 'Created On'
								},
								{
									id: 'actions',
									numeric: false,
									label: 'Actions'
								},
							]}
						/>
						<TableBody>
							{data.map((row) => (
								<TableRow key={row?.id} sx={{ background: row.deleted_at ? red[100] : '' }}>
									<TableCell>{`${row?.id}`}</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>{(new Date(row.created_at!).toLocaleString())}</TableCell>
									<TableCell align="right">
										<MoreMenuButton
											id={row?.id ?? ''}
											trashed={!!row.deleted_at}
											onDelete={(id) => onDelete(id)}
											onReActivate={(id) => onReActivate(id)}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Scrollbar>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 50]}
				component="div"
				count={total}
				rowsPerPage={offset}
				page={pageNumber - 1}
				onPageChange={(event, value) => { onPaginate(value + 1) }}
				onRowsPerPageChange={(e) => { onRowsPerPage(parseInt(e.target.value, 10)) }}
			/>
			<Divider />
		</Card>
	);
}
