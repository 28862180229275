import { IndexResponse } from "../../../../@types/GenericResponses";
import { EntityRelationship } from "../../../../@types/entityRelationship";
import apiClient from "services/apiClient";

interface IndexInterface {
    offset: number
    nextPageNumber: number
    orderBy: string
    sort: string
    text?: string
    additionalFilters?: Array<any>
    linked_mdl_id: string,
}

const index = async (params: IndexInterface): Promise<IndexResponse<EntityRelationship>> => {
    const {  linked_mdl_id, offset, nextPageNumber } = params;
    
    let requestUrl = `/api/entity_relationships?limit=${offset}
    &page=${nextPageNumber == 0 ? 1 : nextPageNumber}
    &mdl_id=${linked_mdl_id}`

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data.data,
        total: response.data.data.total
    };
};

export default {
    index
}