
import React from 'react';
import { Typography } from '@material-ui/core';

const FormDisplayText = (field: any) => {
    return (
        <Typography>{field.title}</Typography>
    )
}

export default FormDisplayText;