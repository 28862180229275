import { useState, ReactNode } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import InfoIcon from '@material-ui/icons/MarkChatUnread';
// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import {
	Box,
	List,
	BoxProps,
	Collapse,
	ListItemText,
	ListItemIcon,
	ListSubheader,
	ListItemButton,
	ListItemButtonProps,
	Grid
} from '@material-ui/core';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
	<ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
	...theme.typography.overline,
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2),
	paddingLeft: theme.spacing(5),
	color: theme.palette.text.primary
}));

interface ListItemStyleProps extends ListItemButtonProps {
	component?: ReactNode;
	to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
	...theme.typography.body2,
	height: 48,
	position: 'relative',
	textTransform: 'capitalize',
	paddingLeft: theme.spacing(5),
	paddingRight: theme.spacing(2.5),
	color: theme.palette.text.secondary,
	'&:before': {
		top: 0,
		right: 0,
		width: 6,
		bottom: 0,
		content: "''",
		display: 'none',
		position: 'absolute',
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4,
		backgroundColor: theme.palette.primary.main
	}
}));

const ListItemIconStyle = styled(ListItemIcon)({
	width: 22,
	height: 22,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
});

// ----------------------------------------------------------------------

type NavItemProps = {
	title: string;
	path?: string;
	key?: string
	condition?: () => boolean;
	icon?: JSX.Element;
	info?: JSX.Element;
	children?: {
		title: string;
		path: string;
	}[];
};

interface NavItemButtonProps {
	item: NavItemProps
	isActive?: boolean
	isSecondary?: boolean
	isShow?: boolean | undefined
	onSelect?: (item: NavItemProps) => void
}


function NavItem({ isSecondary, item, isShow, onSelect = () => {}, isActive = false }: NavItemButtonProps) {
	const theme = useTheme();
	const secondaryColor = theme.palette.grey[600];
	const primaryColor = theme.palette.primary.main;
	const color = isSecondary ? secondaryColor : primaryColor;

	const { pathname } = useLocation();
	const { title, path, icon, info, children } = item;
	let isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;
	isActiveRoot = isActive || isActiveRoot;

	const [open, setOpen] = useState(isActiveRoot);

	const handleOpen = () => {
		setOpen(!open);
	};

	const activeRootStyle = {
		color,
		fontWeight: 'fontWeightMedium',
		bgcolor: alpha(
			color,
			theme.palette.action.selectedOpacity
		),
		'&:before': {
			display: 'block',
			backgroundColor: color,
		}
	};

	const activeSubStyle = {
		color,
		fontWeight: 'fontWeightMedium'
	};

	if (children) {
		return (
			<>
				<ListItemStyle
					disableGutters
					onClick={handleOpen}
					sx={{
						...(isActiveRoot && activeRootStyle)
					}}
				>
					<ListItemIconStyle>{icon && icon}</ListItemIconStyle>

					{isShow && (
						<>
							<ListItemText disableTypography primary={title} />
							{info && info}
							<Box
								component={Icon}
								icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
								sx={{ width: 16, height: 16, ml: 1 }}
							/>
						</>
					)}
				</ListItemStyle>

				{isShow && (
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{children.map((item) => {
								const { title, path } = item;
								const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

								return (
									<ListItemStyle
										disableGutters
										key={title}
										component={RouterLink}
										to={path}
										sx={{
											...(isActiveSub && activeSubStyle)
										}}
									>
										<ListItemIconStyle>
											<Box
												component="span"
												sx={{
													width: 4,
													height: 4,
													display: 'flex',
													borderRadius: '50%',
													alignItems: 'center',
													justifyContent: 'center',
													bgcolor: 'text.disabled',
													transition: (theme) => theme.transitions.create('transform'),
													...(isActiveSub && {
														transform: 'scale(2)',
														bgcolor: color
													})
												}}
											/>
										</ListItemIconStyle>
										<ListItemText disableTypography primary={title} />
									</ListItemStyle>
								);
							})}
						</List>
					</Collapse>
				)}
			</>
		);
	}

	return (
		<ListItemStyle
			onClick={() => onSelect(item)}
			disableGutters
			component={path ? RouterLink : undefined}
			to={path}
			sx={{
				...(isActiveRoot && activeRootStyle)
			}}
		>
			{icon ? <ListItemIconStyle>{icon}</ListItemIconStyle> : <></>}
			{isShow && (
				<>
					<ListItemText disableTypography primary={item.title} />
					{info && info}
				</>
			)}
		</ListItemStyle>
	);
}

interface NavSectionProps extends BoxProps {
	isSecondary?: boolean
	isShow?: boolean | undefined
	onItemSelect?: (item: NavItemProps) => void
	activeItem?: string
	navConfig: {
		subheader: string;
		condition?: () => boolean;
		hasSupport?: boolean;
		pendoClass?: string;
		items: NavItemProps[];
	}[];
}

export default function NavSection({
		isShow = true,
		isSecondary = false,
		onItemSelect,
		activeItem = "-1",
		navConfig,
		...other
	}: NavSectionProps) {
	return (
		<Box {...other}>
			{
				navConfig
					.filter(x => !x.condition || x.condition())
					.map((list) => {
						const { subheader, items, hasSupport, pendoClass } = list;
						return (
							<List key={subheader} disablePadding>
								{
									isShow &&
									<ListSubheaderStyle>
										{subheader}
										{hasSupport && <InfoIcon className={pendoClass} color='inherit' style={{ fontSize: '.75rem', marginLeft: '.5rem' }} />}
									</ListSubheaderStyle>
								}
								{
									items
										.filter(x => !x.condition || x.condition())
										.map((item: NavItemProps) => (
											<NavItem
												key={item.title}
												item={item}
												isShow={isShow}
												onSelect={onItemSelect}
												isActive={activeItem === item.key}
												isSecondary={isSecondary}
											/>
										))
								}
							</List>
						);
					})
			}
		</Box >
	);
}
