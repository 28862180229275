import { useState, useRef, FormEvent } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
// components
import { Contact } from '../../../@types/contact';
import apiClient from 'services/apiClient';
import countries from 'utils/countries';
import HasPermission from 'services/permissionsService';
import AccessPointForm from './AccessPointForm';

const defaultValues = {
  name: '',
  role: '',
  is_primary: '0',
};

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  role: Yup.string()
    .required('Role is required'),
});

interface ContactFormInterface {
  data?: Contact,
  edit: boolean,
  onSave?: (data: any) => void,
  is_primary: string,
  individual_id?: string,
  entity_id?: string,
  isNew?: boolean
}

export default function ContactForm({ data, edit, onSave, is_primary, isNew, individual_id, entity_id }: ContactFormInterface) {
  const [makePrimary, setPrimary] = useState(!!+is_primary);
  const togglePrimary = () => {
    setPrimary(!makePrimary)
  }

  const saveContact = (values: Contact) => {
    if (makePrimary) { values.is_primary = '1' }
    if (data?.id) {
      // UPDATE
      const id = data!.id;
      return apiClient.put(`/api/contacts/${id}`, values);
    } else {
      // CREATE
      return apiClient.post(`/api/contacts`, values);
    }
  }

  const deleteContact = async (id?: string) => {
    if (!window.confirm("You are about to disable this Contact. This record will no longer be maintained. Continue?")) {
      return;
    }
    let requestUrl = `/api/contacts/${id}`;
    await apiClient.delete(requestUrl).catch(err => {
      alert("Error: Could not delete. Please try again.");
    });
    alert("Success");
  }

  const formik = useFormik<Contact>({
    initialValues: data ?? defaultValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm }) => {
      values.is_primary = makePrimary ? '1' : '0';
      values.individual_id = individual_id;
      values.entity_id = entity_id;
      return saveContact(values).then((res) => {
        if (onSave) {
          onSave(res.data.data);
        }
        alert('Success!');
      })
        .catch(err => {
          if (err.response?.data?.message) {
            return alert(err.response.data.message);
          }
          alert('Error: Unable to save. Please try again.');
        });
    }
  });

  const contactRoles = [
    { value: 'self', name: 'Self' },
    { value: 'billing', name: 'Billing' },
    { value: 'management', name: 'Management' },
    { value: 'technical', name: 'Technical' },
    { value: 'other', name: 'Other' }
  ];

  const {
    dirty,
    errors,
    values,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps
  } = formik;


  const canDelete = HasPermission({ permissions: ["can:clientsManagementIndividualsContactsDelete"] }) && individual_id
    || HasPermission({ permissions: ["can:clientsManagementEntitiesContactsDelete"] }) && entity_id;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} className='contact-form'>
          <Grid container rowSpacing={3} >
            {/* contact 1 */}
            <Grid item xs={12}>
              <TextField
                disabled={!edit}
                fullWidth
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!edit}
                select
                fullWidth
                label="Role"
                {...getFieldProps('role')}
                SelectProps={{ native: true }}
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
              >
                <option value="" />
                {contactRoles.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Stack direction="column" spacing={1} paddingBottom={2}>
            {
              edit ?
              <Button fullWidth
              onClick={
                () => {
                  if (!values?.access_points) {
                    setFieldValue('access_points', [{ type: 'mobile', value: '', is_primary: '0' }]);
                  } else {
                    setFieldValue('access_points', [...values?.access_points, { type: 'mobile', value: '', is_primary: '0' }]);
                }
              }}
            >Add Access Point</Button> : <></>
          }
            {
              values?.access_points?.map((accessPoint, index) => {
                return <AccessPointForm
                  key={index}
                  editMode={edit}
                  data={accessPoint}
                  onChange={(data) => {
                    const newAccessPoints = values?.access_points?.map((ap, i) => {
                      if (i === index) {
                        return data;
                      }
                      return ap;
                    });
                    setFieldValue('access_points', newAccessPoints);
                  }}
                />
              })
            }
          </Stack>

          <Stack direction="row" spacing={6}>
            {edit ?
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!dirty}
              >
                Save Record
              </LoadingButton>
              : <></>}

            {edit && data?.id && !+is_primary ?
              <LoadingButton
                fullWidth
                size="large"
                onClick={togglePrimary}
                type="submit"
                variant="outlined"
                loading={isSubmitting}
              >
                Make Primary
              </LoadingButton>
              : <></>
            }

            {
              edit && data?.id && !+is_primary && canDelete
                ? <LoadingButton
                  fullWidth
                  size="large"
                  onClick={() => deleteContact(data?.id)}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Delete
                </LoadingButton>
                : <></>
            }
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
