import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useContext, useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { AppContextDispatcher, AppContextProvider } from 'contexts/appContext';
import apiClient from 'services/apiClient';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: PATH_DASHBOARD.general.app
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_DASHBOARD.settings.user_management
  },
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: PATH_DASHBOARD.settings.user_management
  // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user } = useContext(AppContextProvider);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const triggerEvent = useContext(AppContextDispatcher);
  const appContext = useContext(AppContextProvider);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const endpoint = appContext.guestLoggedIn ? '/api/guest/logout' : '/api/logout';
    try {
      await apiClient.get(endpoint)
      .catch((error) => {
        if(error.response.status === 401) {
          return;
        }
        throw error;
      });
      await triggerEvent({ event: 'logout' });
      if (isMountedRef.current) {
        if (appContext.loggedIn) {
          navigate('/');
        } else if (appContext.guestLoggedIn) {
          // hard rediect to https://www.infomarketbz.com/
          window.location.href = 'https://www.infomarketbz.com/';
        }
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {
          appContext.loggedIn
          ? <>
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.email}
            </Typography>
          </Box>
  
          <Divider sx={{ my: 1 }} />
          </>
          : <> </>
        }
        {
          appContext.loggedIn
            ? MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={`${option.linkTo}/${user?.id}`}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            )) : <></>
        }

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
