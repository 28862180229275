import { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Card, CardContent, CardHeader, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { Contact } from '../../../@types/contact';
import ContactForm from './ContactForm';
import HasPermission from 'services/permissionsService';

interface ContactCardInterface {
    data?: Contact,
    individual_id?: string,
    entity_id?: string,
    is_primary: string

}
export default function ContactCard({ data, individual_id, entity_id, is_primary }: ContactCardInterface) {
    const [editMode, setEdit] = useState(false);
    const canEdit = HasPermission({ permissions: ["can:clientsManagementIndividualsContactsUpdate"] }) && individual_id
        || HasPermission({ permissions: ["can:clientsManagementEntitiesContactsUpdate"] }) && entity_id;

    return <Card>
        <CardHeader
            action={
                canEdit 
                ? <FormControlLabel
                    control={<Switch
                            checked={editMode}
                            onChange={
                                () => { setEdit(!editMode) }
                            }
                            name="editMode"
                        />
                    }
                    label="Edit Mode"
                /> : <></>
            }
            title={<Typography component="h1" variant="h3">Contact {data?.is_primary == '1' ? "(Primary)" : ""} </Typography>}      
        />
         
        <CardContent>
            <ContactForm
                data={data}
                edit={editMode}
                individual_id={individual_id}
                entity_id={entity_id}
                is_primary={is_primary}
                isNew={false}
            />
        </CardContent>
    </Card>
}