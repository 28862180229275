import React from 'react';
import Page from 'components/Page'
import { UsersQueries } from '../users/queries';
import CollectionView from 'components/CollectionView';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import apiClient from 'services/apiClient';

const PortalUsers = () => {
    const nav = useNavigate();
    const deleteUser = async (id: string) => {
		if (!window.confirm("You are about to disable this record. This user will no longer have access to the system. Continue?")) {
			return;
		}
		let requestUrl = `/api/users/${id}`;
		await apiClient.delete(requestUrl).then(() => {
			alert("Success");
		}).catch(err => {
			alert("Error: Could not delete. Please try again.");
		});
	}
    const reactivateUser = async (id: string) => {
		if (!window.confirm("You are about to re-activate this user. Continue?")) {
			return;
		}
		let requestUrl = `/api/users/${id}/reactivate`;
		await apiClient.get(requestUrl).then(() => {
			alert("Success");
		}).catch(err => {
			alert("Error: Could not re-activate user. Please try again.");
		});
	}
    
    return (
        <Page title="Portal Users">
            <CollectionView
                title="Portal Users"
                actions={
                    [
                        {
                            id: 'view',
                            name: 'View',
                            method: (model) => {
                                nav(PATH_DASHBOARD.accounts.portal_users + '/' + model.id);
                            }
                        },
                        {
                            id: 'delete',
                            name: 'Delete',
                            method: (model) => {
                                deleteUser(model.id);
                            }
                        },
                        {
                            id: 'reactivate',
                            name: 'Reactivate',
                            method: (model) => {
                                reactivateUser(model.id);
                            }
                        }
                    ]
                }
                fields={[
                    { id: 'id', label: 'ID', sortable: true },
                    { id: 'name', label: 'Name', sortable: true },
                    { id: 'email', label: 'Email', sortable: true },
                    { id: 'created_at', label: 'Created At', sortable: true },
                ]}
                queryFn={(filters) => UsersQueries.fetchUsers({ ...filters })}
                queryKey={'portal-users'}
                orderBy="id"
                sort="asc"
                initialFilters={[
                    { name: 'disabled', value: '0' },
                    { name: 'role', value: 'portal_user' }
                ]}
                onNewRecord={() => {
                    nav(PATH_DASHBOARD.accounts.portal_users + '/new');
                }}
                supportedFilters={[
                    {
                        name: 'disabled',
                        text: 'Show Disabled',
                        options: [
                            { value: '1', label: 'Yes' },
                            { value: '0', label: 'No' }
                        ]
                    }
                ]}
            />
        </Page>
    )
}

export default PortalUsers;