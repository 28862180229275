import { Form } from 'formik';
import React, { ReactNode, createContext, useEffect, useState } from 'react';

interface Field {
    id: string;
    title: string;
    label?: string;
    type: string;
    required: boolean;
    hidden?: boolean;
    disabled?: boolean;
    value?: any;  // Value can be any type depending on the field
    options?: any;
    textType?: string;
}

export interface Section {
    index: number;
    id: string;
    title: string;
    fields: Field[];
    selected?: boolean;
    hidden?: boolean;
}

export interface FormState {
    mode?: 'interactive' | 'editable' | 'read-only';
    sections:  Array<Section> ;
}

interface FormContextType {
    formState: FormState;
    toggleFieldHidden: (sectionId: string, fieldId: string) => void;
    toggleFieldRequired: (sectionId: string, fieldId: string) => void;
    toggleSectionHidden: (sectionId: string) => void;
    moveSection: (fromIndex: number, toIndex: number) => void;
    selectSection: (sectionId: string) => void;
    setFieldAttribute: (sectionId: string, fieldId: string, attribute: string, value: any) => void;
}

export const FormSectionsToContext = (sections: Array<any>): FormState => {
    const state: FormState = {
        sections: [],
    }
    sections.forEach((section: any, idx: number) => {
        const stateFields: Array<Field> = [];
        section.fields.forEach((field: any) => {
            const stateField: Field = {
                id: field.id,
                title: field.title,
                type: field.type,
                required: false,
                hidden: false,
                ...field
            }
            stateFields.push(stateField);
        });

        const id = section.id ?? `section-${idx}`;
        const stateSection: Section = {
            index: idx,
            id,
            title: section.title,
            hidden: false,
            selected: idx === 0,
            fields: stateFields
        };
        state.sections[idx] = stateSection;
    });

    return state;
}

export const DynamicFormContext = createContext<FormContextType | undefined>(undefined);
interface DynamicFormProviderProps {
    children: ReactNode;
    initialState: FormState;
    initialSection?: string;
    onChange?: (formState: FormState) => void;
}
export const DynamicFormProvider = ({ children, initialState, initialSection, onChange = ()=>{}}: DynamicFormProviderProps) => {
    // sort using index
    initialState.sections.sort((a, b) => a.index - b.index);
    
    // deselect all selected sections
    initialState.sections.forEach((section, idx) => {
        section.selected = false;
    });
    // Set first item as selected on initalize, deselect all other items
    if (!initialSection) {
        initialState.sections[0].selected = true;
    } else {
        initialState.sections.forEach((section, idx) => {
            if (section.title === initialSection) {
                section.selected = true;
            }
        });
    }

    const [formState, setFormState] = useState<FormState>(initialState);
    useEffect(() => {
        onChange(formState);
    }, [formState]);
    const toggleFieldHidden = (sectionId: string, fieldId: string) => {
        setFormState(prev => ({
            ...prev,
            sections: prev.sections.map(section => {
                if (section.id === sectionId) {
                    return {
                        ...section,
                        fields: section.fields.map(field =>
                            field.id === fieldId ? { ...field, hidden: !field.hidden } : field
                        )
                    };
                }
                return section;
            })
        }));
    };
    const toggleFieldRequired = (sectionId: string, fieldId: string) => {
        setFormState(prev => ({
            ...prev,
            sections: prev.sections.map(section => {
                if (section.id === sectionId) {
                    return {
                        ...section,
                        fields: section.fields.map(field =>
                            field.id === fieldId ? { ...field, required: !field.required } : field
                        )
                    };
                }
                return section;
            })
        }));
        onChange(formState);
    };
    const setFieldAttribute = (sectionId: string, fieldId: string, attribute: string, value: any) => {
        setFormState(prev => ({
            ...prev,
            sections: prev.sections.map(section => {
                if (section.id === sectionId) {
                    return {
                        ...section,
                        fields: section.fields.map(field =>
                            field.id === fieldId ? { ...field, [attribute]: value } : field
                        )
                    };
                }
                return section;
            })
        }));
        onChange(formState);
    }
    const toggleSectionHidden = (sectionId: string) => {
        setFormState(prev => ({
            ...prev,
            sections: prev.sections.map(section => {
                return section.id === sectionId ? { ...section, hidden: !section.hidden } : section;
            })
        }));
    };
    const moveSection = (fromIndex: number, toIndex: number) => {
        setFormState(prev => {
            const newSections = [...prev.sections];
            const [removedSection] = newSections.splice(fromIndex, 1);
            newSections.splice(toIndex, 0, removedSection);

            // Update the index of each section
            newSections.forEach((section, index) => {
                section.index = index;
            });

            return { ...prev, sections: newSections };
        });
        onChange(formState);
    };
    const selectSection = (sectionId: string) => {
        setFormState(prev => ({
            ...prev,
            sections: prev.sections.map(section => {
                return {
                    ...section,
                    selected: section.id === sectionId
                };
            })
        }));
        onChange(formState);
    }
    return (
        <DynamicFormContext.Provider value={{
            formState,
            toggleFieldHidden,
            toggleFieldRequired,
            toggleSectionHidden,
            moveSection,
            selectSection,
            setFieldAttribute
        }}>
            {children}
        </DynamicFormContext.Provider>
    );
};
