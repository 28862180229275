
import React, { useContext } from 'react';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { FormikContext } from 'formik';

const FormCheckboxInput = (field: any) => {
    const formik = useContext(FormikContext);
    const { getFieldProps, setFieldValue } = formik || {};
    const { value = field.value } = getFieldProps(field.name);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field}
                    checked={value ?? false}
                    onClick={(e: any) => {
                        setFieldValue(field.name, !value);
                    }}
                />
            }
            label={field.title}
        />
    )
}

export default FormCheckboxInput;