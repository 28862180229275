import { AppContextProvider } from "contexts/appContext";
import { useContext } from "react";

export interface HasPermissionsProps {
	roles?: Array<string>
	permissions?: Array<string>
	orPermissions?: Array<string>
}

const HasPermission = ({permissions = [], orPermissions = [], roles = []}: HasPermissionsProps) => {
	const {user} = useContext(AppContextProvider);
	const userPermissions: Array<string> = user?.permissions ?? [];
	const userRoles: Array<string> = user?.roles ?? [];

	// Check if user has ALL permissions
	let hasPermission = permissions.reduce((prev, curr)=>{
		return prev && userPermissions.findIndex((x: any) => x === curr) !== -1;
	}, true);

	// Check if user has some of the needed permissions
	let hasOrPermission = orPermissions.length > 0 ? orPermissions.reduce((prev, curr)=>{
		return prev || userPermissions.findIndex((x: any) => x === curr) !== -1;
	}, false) : true;

	// Check if user has ALL roles
	let hasRoles = roles.reduce((prev, curr)=>{
		return prev && userRoles.findIndex((x: any) => x === curr) !== -1;
	}, true);

	return (hasOrPermission && hasPermission) && hasRoles;
}

export default HasPermission;