import { useState, useRef } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DatePicker from '@material-ui/lab/DatePicker';
import * as Yup from 'yup';
// components
import countries from 'utils/countries';
import genders from 'utils/genders';
import { User } from '../../../@types/user';
import apiClient from 'services/apiClient';

const defaultValues = {
  email: '',
  name: '',
  two_factor_enabled: true
};

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Mininum 2 characters')
    .max(50, 'Maximum 50 characters'),
  email: Yup.string()
    .required('Email is required')
    .email(),
  two_factor_enabled: Yup.boolean()
});

interface UserFormProps {
  data?: User;
  edit: boolean;
  onSave?: (data: any) => void;
  isPortalUser?: boolean;
}

export default function UserForm({ data, edit, onSave, isPortalUser = false }: UserFormProps) {
  const saveUser = (values: User)=>{
    if(data?.id){
      // UPDATE
      const id = data!.id;
      return apiClient.put(`/api/users/${id}`, values);
    } else {
      // CREATE
      return apiClient.post(`/api/users`, values);
    }
  }

  const formik = useFormik<User>({
    initialValues: data ?? defaultValues,
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm }) => {
      values.role = isPortalUser ? 'portal_user' : 'user';
      return saveUser(values)
        .then((res)=>{
        if(onSave){
          onSave(res.data.user);
        }
      })
      .catch(err => {
        if(err.response?.data?.message){
          return alert(err.response.data.message);
        }
        alert('Error: Unable to save. Please try again.');
      });
    }
  });

  const {
    dirty,
    errors,
    values,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ sm: 6 }}>
              <TextField
                disabled = {!edit}
                fullWidth
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ sm: 6 }}>
              <TextField
                disabled = {!edit || !!data?.id}
                fullWidth
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ sm: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled = {!edit}
                    {...getFieldProps('two_factor_enabled')}
                    checked={values.two_factor_enabled}
                    onChange={(e)=>setFieldValue('two_factor_enabled', e.target.checked)}
                  />
                }
                label="Two Factor Auth (Email)"
              />
            </Stack>

            {edit && 
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!dirty}
              >
                Save Record
              </LoadingButton>
            }
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
