
import { tag } from "../../../../@types/tag";
import apiClient from "services/apiClient";

interface IndexInterface {
    offset: number
    nextPageNumber: number
    orderBy: string
    sort: string
    text?: string
}

export interface IndexResponse {
    data: Array<tag>,
    total: number
}
const index  = async (params: IndexInterface) : Promise<IndexResponse> => {
    const { offset, nextPageNumber,  orderBy, sort, text, } = params;
    
    let requestUrl = `/api/tags?limit=${offset}
    &page=${nextPageNumber == 0 ? 1 : nextPageNumber}
    &order_by=${orderBy}
    &sort=${sort}`;

    if (text && text != '') {
        requestUrl += `&text=${text}`;
    }

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data.data,
        total: response.data.data.total
    };
};

export default {
    index
}