import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import {
	Menu,
	Card,
	Table,
	Button,
	Divider,
	MenuItem,
	TableRow,
	TableBody,
	TableCell,
	Typography,
	CardHeader,
	TableContainer,
	TablePagination,
} from '@material-ui/core';
import Scrollbar from './Scrollbar';
import { Entity } from '../@types/entity';
import SortingSelectingHead, { FieldDefinition } from 'pages/components-overview/material-ui/table/sorting-selecting/SortingSelectingHead';
import { red } from '@material-ui/core/colors';
import { MIconButton } from './@material-extend';

interface MoreMenuButtonInterface {
	actions?: Array<TableRowActionDefinition>
	data: any
}

function MoreMenuButton({ actions = [], data }: MoreMenuButtonInterface) {
	const menuRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<>
				<MIconButton ref={menuRef} size="large" onClick={handleOpen}>
					<Icon icon={moreVerticalFill} width={20} height={20} />
				</MIconButton>
			</>
			<Menu
				open={open}
				anchorEl={menuRef.current}
				onClose={handleClose}
				PaperProps={{
					sx: { width: 200, maxWidth: '100%' }
				}}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{actions.map(
					action => action.view
						? action.view(data)
						: <MenuItem key={action.id} onClick={() => action.method ? action.method(data) : () => { }}>
							{action.icon}
							<Typography variant="body2" sx={{ ml: 2 }}>
								{action.name}
							</Typography>
						</MenuItem>
				)}
			</Menu>
		</>
	);
}

export interface TableRowActionDefinition {
	id: string
	name: string
	icon?: any
	method?: (model: any) => void
	view?: (model: any) => any
}

export interface TableViewInterface {
	title: string,
	data: Array<any>
	pageNumber?: number
	offset?: number
	total: number
	orderBy?: string
	sort?: "desc" | "asc"
	fields: Array<FieldDefinition>
	keyBuilder?: (row: any) => string
	actions?: Array<TableRowActionDefinition>
	onNewView?: any
	onNewRecord?: () => void
	onPaginate: (pageNumber: number) => void
	onRowsPerPage: (rowsPerPage: number) => void
	onRequestSort: (field: string) => void
}
export default function TableView(props: TableViewInterface) {
	let { title, data, keyBuilder, fields, actions, pageNumber = 1, offset = 10, total, orderBy = 'id', sort = 'asc', onPaginate, onRowsPerPage, onRequestSort, onNewRecord, onNewView } = props;
	fields = fields.filter(x => !x.condition || x.condition() && x.id !== 'actions');
	const headers = [];
	fields.forEach(x => {
		headers.push(Object.assign({}, x));
	})
	if (actions) {
		headers.push({ id: 'actions', label: 'Actions', sortable: false});
	}
	const buildRow = (row: any) => {
		const fieldElements: Array<any> = [];
		fields.forEach(field => {
			let value = row && row[field.id] ? row[field.id] : '';
			if (field.build) {
				value = field.build(row, row[field.id]);
			}
			fieldElements.push(
				<TableCell id={field.id}>{value}</TableCell>
			);
		})
		let key = row.id;
		if(keyBuilder){
			key = keyBuilder(row);
		}
		return <TableRow key={key} sx={{ background: row.deleted_at ? red[100] : '' }}>
			{fieldElements}
			{
				actions && <TableCell id={'actions'}>
					<MoreMenuButton actions={actions} data={row} />
				</TableCell>
			}
		</TableRow>;
	}

	return (
		<Card>
			<CardHeader
				title={<Typography component="h1" variant="h3">{title}</Typography>} sx={{ mb: 3 }}
				action={
					onNewRecord && (onNewView ? onNewView : <Button onClick={() => { onNewRecord!() }}>New Record</Button>)
				}
			/>
			<Scrollbar>
				<TableContainer sx={{ minWidth: 720 }}>
					<Table>
						<SortingSelectingHead
							selectable={false}
							orderBy={orderBy}
							rowCount={total}
							numSelected={0}
							onRequestSort={(property) => onRequestSort(property)}
							onSelectAllClick={(checked) => { }}
							order={sort}
							headLabel={headers}
						/>
						<TableBody>
							{data.map(row => buildRow(row))}
						</TableBody>
					</Table>
				</TableContainer>
			</Scrollbar>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 50]}
				component="div"
				count={total}
				rowsPerPage={offset}
				page={pageNumber - 1}
				onPageChange={(event, value) => { onPaginate(value + 1) }}
				onRowsPerPageChange={(e) => { onRowsPerPage(parseInt(e.target.value, 10)) }}
			/>
			<Divider />
		</Card>
	);
}
