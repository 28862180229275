import { CustomerFile } from "../../../../@types/file";
import { tag } from "../../../../@types/tag";
import apiClient from "services/apiClient";

interface IndexInterface {
    offset: number
    nextPageNumber: number
    orderBy: string
    sort: string
    text?: string
    additionalFilters?: Array<any>
    linked_mdl_id: string,
    linked_mdl_type: string
    tagids: Array<any>
}

export interface IndexResponse {
    data: Array<CustomerFile>,
    filterTags: Array<tag>,
    systemTags: Array<tag>,
    total: number
}
const index = async (params: IndexInterface): Promise<IndexResponse> => {
    const { tagids, linked_mdl_id, linked_mdl_type, offset, nextPageNumber } = params;
    
    let requestUrl = `/api/files/filtered`
    const requestData = {
        tag_id: tagids,
        type: linked_mdl_type,
        mdl_id: linked_mdl_id,
        limit: offset,
        page: nextPageNumber == 0 ? 1 : nextPageNumber
    };

    const response = await apiClient.post(requestUrl,  requestData);
    return {
        data: response.data.data,
        filterTags: response.data.filterTags,
        systemTags: response.data.systemTags,
        total: response.data.data.total
    };
};

export default {
    index
}