import React, { useState } from 'react';
import TableView, { TableRowActionDefinition } from './TableView';
import { FieldDefinition } from 'pages/components-overview/material-ui/table/sorting-selecting/SortingSelectingHead';
import CustomersListToolbar, { ListFilterProps } from './_dashboard/customers/CustomersListToolBar';
import { useQuery } from '@tanstack/react-query';

interface FilterDefinition {
    name: string
    text: string
    options: Array<{ value: string, label: string }>
}
interface CollectionViewProps {
    title: string
    fields: Array<FieldDefinition>
    queryFn: (filters: any) => Promise<any>
    queryKey?: any
    actions: Array<TableRowActionDefinition>
    orderBy: string
    initialFilters?: Array<{ name: string, value: string }>
    supportedFilters?: Array<FilterDefinition>
    sort: "desc" | "asc"
    onNewView?: any
    onNewRecord?: any
}

const CollectionView = (args: CollectionViewProps) => {
    const { title, fields, actions, orderBy, sort, onNewView, onNewRecord, initialFilters = [], supportedFilters = [] } = args;
    const defaultQueryKey = "collection-" + title.toLowerCase().replace(' ', '_)');
    const { queryFn, queryKey = defaultQueryKey } = args;
    const [state, setState] = useState({
        filters: {
            pageNumber: 1,
            offset: 10,
            nextPageNumber: 0,
            orderBy: 'id',
            sort: 'asc',
            filterId: 0,
            requestedFilters: initialFilters,
            text: ""
        }
    });
    const { filters } = state;

    let { data, refetch } = useQuery<any, Error>(
        [queryKey, filters],
        () => queryFn(filters)
    );
    const { data: records = [], total: recordsTotal = 0 } = data ?? {};

    return <>
        <CustomersListToolbar
            searchFilter={false}
            supportedFilters={supportedFilters}
            initialFilters={{ text: filters.text, filters: filters.requestedFilters || [] }}
            onFilter={({ text, filters: iFilters }: ListFilterProps) => {
                filters.text = text;
                filters.requestedFilters = iFilters;
                filters.filterId++;
                setState((state) => { return { ...state, filters } });
            }}
        />
        <TableView
            title={title}
            fields={fields}
            actions={actions}
            orderBy={orderBy}
            sort={sort}
            data={records}
            onPaginate={
                (current) => {
                    filters.pageNumber = current;
                    filters.nextPageNumber = current + 1;
                    setState((state) => { return { ...state, filters } });
                }
            }
            onNewRecord={onNewRecord}
            onNewView={onNewView}
            onRowsPerPage={
                (rowsPerPage) => {
                    filters.pageNumber = 1;
                    filters.offset = rowsPerPage;
                    setState((state) => { return { ...state, filters } });
                }
            }
            onRequestSort={(field) => {
                filters.orderBy = field;
                const isAsc = orderBy === field && sort === 'asc';
                filters.sort = isAsc ? 'desc' : 'asc';
                setState((state) => { return { ...state, filters } });
            }}
            pageNumber={filters.pageNumber}
            offset={filters.offset}
            total={recordsTotal}
        />
    </>
}

export default CollectionView;