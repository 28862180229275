import { Address } from "../../../../@types/address";
import apiClient from "services/apiClient";

interface IndexInterface {
    linked_mdl_id: string;
    linked_mdl_type: string;
    additionalFilters?: Array<any>
}

export interface IndexResponse {
    data: Array<Address>,
    total: number
}
const index = async (params: IndexInterface): Promise<IndexResponse> => {
    const { linked_mdl_id, linked_mdl_type, additionalFilters = [] } = params;

    let requestUrl = `/api/addresses?limit=
        &mdl_id=${linked_mdl_id}
        &type=${linked_mdl_type}`;

    additionalFilters?.forEach(({ name, value }) => {
        if (!value) {
            value = "0";
        }
        requestUrl += `&${name}=${value}`;
    });

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data.data,
        total: response.data.data.total
    };
};

export default {
    index
}