import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  Select,
  InputAdornment,
  OutlinedInput,
  MenuItem,
  FormControl,
  InputLabel,
  Grid
} from '@material-ui/core';
import { useState } from 'react';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 500,
  marginRight:'1rem',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));
export interface SelectFilterProps {
  name: string
  text: string
  options: Array<{
    value: string
    label: string
  }>
}

type UsersListToolbarProps = {
  initialFilters: ListFilterProps
  supportedFilters?: Array<SelectFilterProps>
  onFilter: (filters: ListFilterProps) => void;
};

export interface ListFilterProps {
  text: string
  filters: Array<{name: string, value: string}>
}

export default function UsersListToolbar({
  initialFilters,
  supportedFilters = [],
  onFilter
}: UsersListToolbarProps) {
  const [state, setState] = useState<ListFilterProps>(initialFilters);
  const { text, filters} = state;

  return (
    <RootStyle>
      <SearchStyle
        value={text}
        onChange={(e) => setState({text: e.target.value, filters})}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        }
      />
      <Grid container spacing={2} width={"100%"}>
        {supportedFilters.map(
          ({name, options, text: filterText}) =>
          {
            const i = filters.findIndex(x=>x.name === name);

            return <Grid item width={"100%"}>
                <FormControl fullWidth>
                  <InputLabel id={name}>{filterText}</InputLabel>
                  <Select labelId={name} label={filterText} value={i > -1 ? filters[i].value : '-1'} onChange={(e)=>{
                    const value = e.target.value as string;
                    if(i !== -1){
                      filters[i].value = value;
                    } else {
                      filters.push({name, value});
                    }
                    setState({text, filters});
                  }}>
                  {options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          }
        )}
      </Grid>
      <Tooltip title="Filter list">
        <IconButton onClick={()=>{
          onFilter(state);
        }}>
          <Icon icon={roundFilterList} />
        </IconButton>
      </Tooltip>
    </RootStyle>
  );
}
