
import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@material-ui/lab';

import { FormikContext } from 'formik';

const FormDateInput = (field: any) => {
    const formik = useContext(FormikContext);
    const { getFieldProps, setFieldValue } = formik;
    const { touched, errors } = formik;
    return (
        <DatePicker
            label={field.label}
            {...getFieldProps(field.name)}
            onChange={(date) => setFieldValue(field.name, date)}
            renderInput={(params) => (
                <TextField
                fullWidth
                {...field}
                {...params}
                error={Boolean(touched[field.name] && errors[field.name])}
                />
            )}
            disabled={field.disabled}
            inputFormat="dd/MM/yyyy"
        />
    )
}

export default FormDateInput;