import { AppContextProvider } from 'contexts/appContext';
import { useState, ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import GuestAuthPage from 'pages/guest/GuestAuthPage';

// ----------------------------------------------------------------------

type GuestAuthGuardProps = {
  children: ReactNode;
};

export default function GuestAuthGuard({ children }: GuestAuthGuardProps) {
	const {guestLoggedIn} = useContext(AppContextProvider);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!guestLoggedIn) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <GuestAuthPage/>;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
