import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Stack } from '@material-ui/core';
import { Section } from '../form-editor/FormEditorProvider';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { GenericFormInput } from '.';
import { LoadingButton } from '@material-ui/lab';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';

interface DynamicFormProps {
    formDefinition: Section;
    editSwitcher?: boolean;
    editMode?: boolean;
    initialState?: any;
    handleFormChange?: (formValues: any) => void;
    onSubmit?: (formValues: any) => void;
    submitText?: string;
    allowReinitialize?: boolean;
}

const DynamicForm = ({
        formDefinition,
        handleFormChange,
        onSubmit,
        initialState = {},
        editSwitcher = true,
        editMode = false,
        submitText = 'Submit',
        allowReinitialize
    }: DynamicFormProps
) => {
    const [isEditing, setIsEditing] = useState(editMode);
    const { id, title, fields } = formDefinition;

    // Compose Validation Schema based on field types and attributes
    const validationSchema = fields.reduce((acc: any, field: any) => {
        if (field.hidden) {
            return acc;
        }

        switch (field.type) {
            case 'text':
                acc[field.id] = Yup.string();
                break;
            case 'number':
                acc[field.id] = Yup.number();
                break;
            case 'boolean':
                acc[field.id] = Yup.boolean();
                break;
            case 'select':
                acc[field.id] = Yup.string();
                break;
            case 'file':
                acc[field.id] = Yup.mixed();
                break;
            default:
                acc[field.id] = Yup.string();
                break;
        }
        if (field.required) {
            acc[field.id] = acc[field.id].required('Required');
        }
        return acc;
    }, {});

    const defaultState: any = {};
    fields.forEach((field: any) => {
        defaultState[field.id] = initialState[field.id] ?? field.value ?? undefined;
    });
    const defaultFormik = useFormik({
        initialValues: defaultState,
        enableReinitialize: allowReinitialize,
        validateOnMount: true,
        validationSchema: Yup.object().shape(validationSchema),
        onSubmit: (submittedData) => {
            if (onSubmit) {
                return onSubmit(submittedData);
            }
            return Promise.resolve();
        },
    });

    const formik = defaultFormik;
    const { getFieldProps, values, isSubmitting, isValid, handleSubmit, errors, dirty } = formik;

    // Add listener to formik values
    useEffect(() => {
        if(handleFormChange) {
            handleFormChange(values);
        }
    }, [values]);

    return (
        <Grid container>
            {
                editSwitcher
                    ? <Grid container xs={12} justifyContent="flex-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isEditing}
                                    onChange={
                                        () => { setIsEditing(!isEditing) }
                                    }
                                    name="editMode"
                                />
                            }
                            label="Edit Mode"
                        />
                    </Grid>
                    : <></>
            }
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            {fields.map((field: any, fieldIndex: number) => {
                                // deep copy field instance
                                const fieldInstance = { ...field };
                                fieldInstance.disabled = !isEditing || fieldInstance.disabled;
                                if (fieldInstance.hidden) {
                                    return null;
                                }
                                const id = fieldInstance.id ? fieldInstance.id : fieldInstance.title.toLowerCase().replace(/ /g, '_');
                                const fieldProps = getFieldProps(id);
                                const mergedFields = { ...fieldInstance, ...fieldProps };
                                field.name = id;

                                return (
                                    <Stack key={fieldIndex} direction="row" spacing={1} alignItems="center">
                                        <GenericFormInput field={mergedFields} />
                                        {
                                            errors[id] && (
                                                <Typography variant="caption" color="error">
                                                    {errors[id]}
                                                </Typography>
                                            )
                                        }
                                    </Stack>
                                );
                            })}
                            <Stack key={'submit'} direction="row" spacing={1} alignItems="flex-end" justifyContent="flex-end" >
                                {
                                    (isEditing && onSubmit)
                                        ? <LoadingButton
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {submitText}
                                        </LoadingButton>
                                        : <></>
                                }
                            </Stack>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Grid>
        </Grid>
    );
};
export default DynamicForm;