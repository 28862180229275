import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Container, Typography } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { SetNewPasswordForm } from '../../components/authentication/reset-password';
//
import { SentIcon } from '../../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function SetNewPassword() {
	const nav = useNavigate();
	return (
		<RootStyle title="Reset Password">
			<LogoOnlyLayout />

			<Container>
				<Box sx={{ maxWidth: 480, mx: 'auto' }}>
					<>
						<Typography variant="h3" paragraph>
							Password Reset
						</Typography>
						<Typography sx={{ color: 'text.secondary', mb: 5 }}>
							Please enter your new password to continue.
						</Typography>

						<SetNewPasswordForm
							onSave={() => nav('/auth/login', {replace: true})}
						/>

						<Button
							fullWidth
							size="large"
							component={RouterLink}
							to={PATH_AUTH.login}
							sx={{ mt: 1 }}
						>
							Back
						</Button>
					</>
				</Box>
			</Container>
		</RootStyle>
	);
}
