import React from 'react';
import ThemeConfig from './theme';
import NotistackProvider from 'components/NotistackProvider';
import RtlLayout from './components/RtlLayout';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import Router from './routes';
import AppContextWrapper from 'contexts/appContext';

function App() {
  return (
    <AppContextWrapper>
      <ThemeConfig>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <Router />
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeConfig>
    </AppContextWrapper>
  );
}

export default App;
