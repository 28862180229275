import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import apiClient from 'services/apiClient';

// ----------------------------------------------------------------------

type InitialValues = {
	email: string;
	afterSubmit?: string;
};

type ResetPasswordFormProps = {
	onSent: VoidFunction;
	onGetEmail: (value: string) => void;
};

export default function ResetPasswordForm({ onSent, onGetEmail }: ResetPasswordFormProps) {
	const { resetPassword } = { resetPassword: () => { } };
	const isMountedRef = useIsMountedRef();

	const ResetPasswordSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required')
	});

	const formik = useFormik<InitialValues>({
		initialValues: {
			email: ''
		},
		validationSchema: ResetPasswordSchema,
		onSubmit: async (values, { setErrors, setSubmitting }) => {
			let requestUrl = `api/user/reset/sendPassword`;
			apiClient.post(requestUrl, { email: values.email }).then(res => {
				if (isMountedRef.current) {
					onSent();
					onGetEmail(formik.values.email);
					setSubmitting(false);
				}
			})
			.catch(error => {
				let errMessage = "Error: Could not reset the password";
				if(error.response?.data?.message){
					errMessage = error.response?.data?.message;
				}
				if (isMountedRef.current) {
					setErrors({ afterSubmit: errMessage });
					setSubmitting(false);
				};
			});
		}
	});

	const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

	return (
		<FormikProvider value={formik} >
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					{errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

					<TextField
						fullWidth
						{...getFieldProps('email')}
						type="email"
						label="Email address"
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
					/>

					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						loading={isSubmitting}
					>
						Reset Password
					</LoadingButton>
				</Stack>
			</Form>
		</FormikProvider >
	);
}
