import { Button, Grid, Typography } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { AppContextProvider } from 'contexts/appContext';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import apiClient from 'services/apiClient';

const FormFileInput = (field: any) => {
    const formMik = useContext(FormikContext);
    const { getFieldProps, setFieldValue } = formMik || {};
    const { value } = getFieldProps(field.name);

    const isFile = value instanceof File;
    if (isFile) {
        return <FilePendingUploadView key={field.name} field={field} value={value} />;
    } else if (value) {
        return <FileUploadedView key={field.name} field={field} value={value} setFieldValue={setFieldValue} />;
    }

    return <FormFileUploader key={field.name} field={field} setFieldValue={setFieldValue} value={value} />;
}

const FormFileUploader = ({ field, setFieldValue, value }: any) => {
    const fileName = value ? value[0].name : null;

    const handleButtonClick = () => {
        document.getElementById(field.name + '_input')?.click();
    };

    return <Grid container>
        <Grid item xs={3}>
            <InputLabel sx={{ width: '100%' }}>
                {field.required ?? false ? <span style={{ color: 'red' }}>* </span> : null}
                {field.title}
            </InputLabel>
        </Grid>
        <Grid item xs={6}>
            <Typography variant='subtitle2' >{fileName ?? 'None'}</Typography>
        </Grid>
        {
            !field.disabled
            ? <Grid item xs={3}>
            <input
                id={field.name + '_input'}
                type="file"
                onChange={(event) => {
                    if (event.currentTarget.files) {
                        setFieldValue(field.name, event.currentTarget.files[0]);
                    }
                }}
                multiple={false}
                disabled={field.disabled || false}
                style={{ display: 'none' }}
            />
            <Button
                variant="contained"
                component="span"
                onClick={handleButtonClick}
                disabled={field.disabled}
            >
                Upload
            </Button>
            </Grid> : <></>
        }
    </Grid>
}

const FileUploadedView = ({ field, value }: any) => {
	const { user } = useContext(AppContextProvider);

    const formMik = useContext(FormikContext);
    const { setFieldValue } = formMik || {};
    const editable = !field.disabled;

    const onView = async () => {
		let requestUrl = `/api/guest/files/url`;
        if (user){
            requestUrl = `/api/files/url`;
        }
		const requestData = value;
		apiClient.post(requestUrl, requestData)
            .then((response)=>{
                window.open(response.data?.data, '_blank')
            }).catch(()=>{
                alert("Oops! We cannot open the request file. Please contact support.")
            });
	}

    const onDelete = async () => {
        let requestUrl = `/api/guest/files/delete`;
        const path = value.cloud_path;
        const requestData = value;
        const response = await apiClient.post(requestUrl, requestData);
        setFieldValue(field.name, undefined);
    }

    return <Grid container>
        <Grid item xs={3}>
            <InputLabel sx={{ width: '100%' }}>
                {field.required ?? false ? <span style={{ color: 'red' }}>* </span> : null}
                {field.title}
            </InputLabel>
        </Grid>
        <Grid item xs={6}>
            <Typography variant='subtitle2' >{value.name}</Typography>
        </Grid>
        <Grid item xs={3}>
            {
                editable
                ? <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    onDelete();
                }}
            >
                Remove
            </Button>
            : <></>
        }
            <Button
                variant="contained"
                component="span"
                onClick={()=>{onView()}}
            >
                View
            </Button>
        </Grid>
    </Grid>
}

const FilePendingUploadView = ({ field, value }: any) => {
    const formMik = useContext(FormikContext);
    const { setFieldValue } = formMik || {};
    const editable = !field.disabled;

    return <Grid container>
        <Grid item xs={3}>
            <InputLabel sx={{ width: '100%' }}>
                {field.required ?? false ? <span style={{ color: 'red' }}>* </span> : null}
                {field.title}
            </InputLabel>
        </Grid>
        <Grid item xs={6}>
            <Typography variant='subtitle2' >{value.name}</Typography>
        </Grid>
        <Grid item xs={3}>
            {
                editable
                ? <Button
                variant="contained"
                color="primary"
                onClick={() => {
                     setFieldValue(field.name, undefined);
                }}
            >
                Remove
            </Button>
            : <></>
        }
        </Grid>
    </Grid>
}
export default FormFileInput;