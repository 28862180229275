import { useState, useRef, useEffect } from 'react';
// material
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Grid,
	Typography
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { BasicAML, BasicClientManagement, BasicSecurities, GetStarted } from 'utils/pendo_guides';
// ----------------------------------------------------------------------

export default function FeatureGuidesPopUp() {
	const [open, setOpen] = useState(false);
	const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

	const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
		setOpen(true);
		setScroll(scrollType);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<div>
			<Button variant='contained' onClick={handleClickOpen('paper')} sx={{ mr: 2 }}>
				Feature Guides
			</Button>

			<Dialog open={open} onClose={handleClose} scroll={scroll} maxWidth={'lg'}>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText
						id="feature-guides"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<Grid xs={12} paddingLeft={3} paddingRight={3}>
							<Grid container xs={12}>
								<Grid item justifyContent="flex-end">
									<Typography gutterBottom variant="h5">Basics Guide</Typography>
									<Typography variant="body2">Shows you the application basics.</Typography>
								</Grid>
								<Grid container justifyContent="flex-end">
									<Button className={GetStarted} onClick={handleClose}>Start</Button>
								</Grid>
							</Grid>
							<Grid container xs={12}>
								<Grid item justifyContent="flex-end">
									<Typography gutterBottom variant="h5">Client Management Guide</Typography>
									<Typography variant="body2">Shows you where and how you can keep track of your customers.</Typography>
								</Grid>
								<Grid container justifyContent="flex-end">
									<Button className={BasicClientManagement} onClick={handleClose}>Start</Button>
								</Grid>
							</Grid>
							<Grid container xs={12}>
								<Grid item justifyContent="flex-end">
									<Typography gutterBottom variant="h5">AML Guide</Typography>
									<Typography variant="body2">Goes over the AML solution and it's most common workflows.</Typography>
								</Grid>
								<Grid container justifyContent="flex-end">
									<Button className={BasicAML} onClick={handleClose}>Start</Button>
								</Grid>
							</Grid>
							<Grid container xs={12}>
								<Grid item justifyContent="flex-end">
									<Typography gutterBottom variant="h5">Securities Guide</Typography>
									<Typography variant="body2">Goes over the Securities solution and it's most common workflows.</Typography>
								</Grid>
								<Grid container justifyContent="flex-end">
									<Button className={BasicSecurities} onClick={handleClose}>Start</Button>
								</Grid>
							</Grid>
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	);
}
