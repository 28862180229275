import { Button } from "@material-ui/core";
import { UserPortalAccess } from "../../../@types/user_portal_access";
import ClientSelector from "components/ClientSelector";
import { useState } from "react";
import apiClient from "services/apiClient";

interface PortalUserAccessProps {
    data?: UserPortalAccess
    onSave: (data: any) => void
    edit?: boolean
}

const PortalUserAccess = ({ data, onSave, edit }: PortalUserAccessProps) => {
    const individualId = data?.mdl_type == '1' ? data.mdl_id : undefined;
    const entityId = data?.mdl_type == '2' ? data.mdl_id : undefined;

    const [state, setState] = useState({
        individual_id: individualId,
        entity_id: entityId,
    });
    const { individual_id, entity_id } = state;
    
    return (
        <div>
            <ClientSelector
                disabled={!edit}
                individualId={individual_id}
                entityId={entity_id}
                onClientSelect={function ({indId, entityId}: any): void {
                    setState({ ...state, individual_id: indId, entity_id: entityId });
                }}
                onClear={function (): void {
                    setState({ ...state, individual_id: undefined, entity_id: undefined });
                }}
            />
            <Button variant="contained" color="primary" onClick={() => onSave(state)} >Save</Button>
        </div>
    );
}

export default PortalUserAccess;