import apiClient from "services/apiClient";

interface IndexInterface {
    offset: number
    nextPageNumber: number
    orderBy: string
    sort: string
    text?: string
    additionalFilters?: Array<any>
    linked_mdl_id: string
    type:string | undefined
}
interface Customers{
    id: string
    name: string
    linked_mdl_type: string
}

export interface IndexResponse {
    data: Array<Customers>,
    total: number
}
const index = async (params: IndexInterface): Promise<IndexResponse> => {
    const {offset, nextPageNumber, linked_mdl_id, type, text, sort, orderBy } = params;
    
    let requestUrl = `/api/entity_relationships/show?limit=${offset}
    &page=${nextPageNumber == 0 ? 1 : nextPageNumber}
    &linked_mdl_id=${linked_mdl_id}
    &type=${type}
    &sort=${sort}
    &order_by=${orderBy}`;

    if (text && text != '') {
        requestUrl += `&text=${text}`;
    }

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data.data,
        total: response.data.data.total
    };
};

export default {
    index
}