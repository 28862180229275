import Page from 'components/Page'
import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Stack, Switch, Typography } from '@material-ui/core';
import BackArrow from '@material-ui/icons/ArrowBack';
import apiClient from 'services/apiClient';
import { Contact } from '../../../../@types/contact';
import LoadingScreen from 'components/LoadingScreen';
import { useLocation, useNavigate } from 'react-router';
import { AppContextProvider } from 'contexts/appContext';
import { useQuery } from "@tanstack/react-query";
import { ContactQueries } from '../queries';
import ErrorScreen from 'components/ErrorScreen';
import HasPermission from 'services/permissionsService';
import { IndexResponse } from '../queries/contacts';
import ContactCard from 'components/_dashboard/contacts/ContactCard';
import { ContactForm } from 'components/_dashboard/contacts';

export default function Contacts() {
	const location = useLocation();
	const pieces = location.pathname.split('/');
	const mdl_id = pieces[pieces.length - 2];
	const type = pieces[pieces.length - 3];

	let type_id = '1';
	let individual_id : any = undefined;
	let entity_id : any = undefined;
	if (type == "individuals") {
		individual_id = mdl_id;
		type_id = '1'
	}else if(type == "entities"){
		entity_id = mdl_id;
		type_id = '2'
	}

	const [showCard, setShowCard] = useState(false);
	const nav = useNavigate();

	let { data, isLoading: loading, error } = useQuery<IndexResponse, Error>(

		[`contacts`],
		() => ContactQueries.index({
			linked_mdl_type: type_id,
			linked_mdl_id: mdl_id,
		})
	);
	if (loading) {
		return <LoadingScreen />;
	}
	if (error) {
		return <ErrorScreen />;
	}

	const { data: records = [], total: recordsTotal = 0 } = data!;
	records.sort((a, b) => {
		return +(b.is_primary) - +(a.is_primary)
	});

	const toggleCard = () => {
		setShowCard(!showCard);
	}
	return (
		<Page title="Individual Contacts">
			<Container>
				<Button onClick={() => { nav(-1); }}><BackArrow /></Button>
				{
					(HasPermission({ permissions: ["can:clientsManagementIndividualsContactsCreate"] }) && type_id == '1')
					|| (HasPermission({ permissions: ["can:clientsManagementEntitiesContactsCreate"] }) && type_id == '2')
					? <Grid container justifyContent="end" padding={2}>
						<Button variant="contained" color="error" sx={{ marginRight: '1rem' }} onClick={toggleCard}>Add Contact</Button>
					</Grid> : <></>
				}
				<Box height="1rem" />
				{
					data && Object.keys(data).length !== 0 && (
						<Grid container spacing={2}>
							{records.map((record) => {
								return <Grid item key={record.id} width={"100%"}>
									<ContactCard
										data={record}
										individual_id = {individual_id}
										entity_id = {entity_id}
										is_primary={record.is_primary}
									/>
								</Grid>
							})}
						</Grid>
					)
				}
				{
					!records || records.length == 0
					? <Typography component="h1" variant="h3" textAlign="center">No Contacts Found</Typography>
					: <></>
				}

				<div>
					<Dialog open={showCard} onClose={toggleCard} maxWidth={'lg'}>
						<DialogTitle sx={{ pb: 2 }}>
							<Typography padding={2} component="h1" variant="h3"> Add Contact</Typography>
						</DialogTitle>
						<DialogContent>
						<Box height="1rem" />
							<ContactForm
								edit={true}
								individual_id = {individual_id}
								entity_id = {entity_id}
								is_primary={'0'}
								isNew={true}
								onSave={toggleCard}
							/>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={toggleCard}>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</Container>
		</Page>
	)
}