const BasicAML = 'pendo-guide_basic-aml';
const BasicSecurities = 'pendo-guide_basic-securities';
const BasicClientManagement = 'pendo-guide_basic-client-management';
const GetStarted = 'pendo-guide_get-started';

export {
	BasicAML,
	BasicSecurities,
	BasicClientManagement,
	GetStarted
}