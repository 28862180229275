import { AppContextProvider } from 'contexts/appContext';
import { ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { loggedIn, require_2fa } = useContext(AppContextProvider);

  if (loggedIn && require_2fa) {
    return <Navigate to={PATH_DASHBOARD.auth.verify_2fa} />;
  }

  if (loggedIn) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
