import { useState } from 'react';
import { AccessPoint } from '../../../@types/contact';
import { Grid, TextField } from '@material-ui/core';

interface AccessPointFormProps {
    data?: AccessPoint
    onChange: (data: AccessPoint) => void
    editMode?: boolean
}
const AccessPointForm = ({data, onChange, editMode = true}: AccessPointFormProps) => {
    const defaulValue: AccessPoint = {
        type: 'mobile',
        value: '',
        is_primary: '0'
    };
    const types : Array<any> = [
        { name: 'Mobile', value: 'mobile' },
        { name: 'Email', value: 'email' },
        { name: 'Phone', value: 'phone' },
        { name: 'Fax', value: 'fax' },
        { name: 'Website', value: 'website' },
    ];
    const [accessPoint, setAccessPoint] = useState<AccessPoint>(data ?? defaulValue);
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
            <TextField
                disabled={!editMode}
                select
                fullWidth
                label="Type"
                value={accessPoint.type}
                SelectProps={{ native: true }}
                onChange={(e) => {
                    setAccessPoint({ ...accessPoint, type: e.target.value });
                    onChange({ ...accessPoint, type: e.target.value });
                }}
              >
                <option value="" />
                {types.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    disabled={!editMode}
                    label="Value"
                    value={accessPoint.value}
                    onChange={(e) => {
                        setAccessPoint({ ...accessPoint, value: e.target.value });
                        onChange({ ...accessPoint, value: e.target.value });
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default AccessPointForm;