// hooks

//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import { useContext } from 'react';
import { AppContextProvider } from 'contexts/appContext';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
	const { user } = useContext(AppContextProvider);

  return (
    <MAvatar
      src={undefined}
      alt={user?.name ?? ''}
      color={user && user?.name ? createAvatar(user!.name).color : 'default'}
      {...other}
    >
      {createAvatar(user?.name ?? 'Default').name}
    </MAvatar>
  );
}
