
import { IndexResponse } from "../../../../@types/GenericResponses";
import { IndexRequest, ShowRequest } from "../../../../@types/GenericRequests";
import { User } from "../../../../@types/user";
import apiClient from "services/apiClient";

const fetchUser = async (params: ShowRequest) : Promise<any> => {
    let requestUrl = `/api/users/${params.id}`;

    if(params.id === 'new'){
        return {
            data: undefined
        };
    }

    if(params.trashed){
        requestUrl = `/api/users/trashed/${params.id}`;
    }

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data
    };
};

const fetchUsers = async (params: IndexRequest): Promise<IndexResponse<User>> => {
    let requestUrl = `/api/users?limit=${params.offset}&page=${params.pageNumber}&order_by=${params.orderBy}&sort=${params.sort}`;

    if (params.text && params.text !== '') {
        requestUrl += `&text=${params.text}`;
    }

    params.requestedFilters?.forEach(({ name, value }) => {
        requestUrl += `&${name}=${value}`;
    });

    try {
        const response = await apiClient.get(requestUrl);
        return {
            data: response.data.data.data,
            total: response.data.data.total
        };
    } catch (err) {
        return {
            data: [],
            total: 0
        };
    }
};

const fetchUserPortalAccess = async (params: ShowRequest) : Promise<any> => {
    let requestUrl = `/api/users/${params.id}/portal_access`;

    if(params.id === 'new'){
        return {
            data: undefined
        };
    }

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data
    };
}

const fetchSupportUser = async () => {
    let requestUrl = `/api/users/support`;

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data
    };
}

export default {
    fetchUsers,
    fetchUser,
    fetchSupportUser,
    fetchUserPortalAccess
};