import apiClient from "services/apiClient";

interface PermissionsInterface {
    user_id: number
    perm_id?: number
}

export interface GenericResponse {
    data: any
}

const listAllPermissions = async () : Promise<GenericResponse> => {
    let requestUrl = `/api/permissions/list_all_permissions`;

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data
    };
}

const listAllPermissionsForUser = async (params: PermissionsInterface) : Promise<GenericResponse> => {
    const { user_id, perm_id } = params;

    if(!user_id){
        return {
            data: undefined
        };
    }

    let requestUrl = `/api/permissions/list_all_permissions_for_user?user_id=${user_id}`;
    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data
    };
}

interface SyncUserPermissionsRequestInterface {
    user_id: number
    permissions: string[]
}

const syncPermissionsForUser = async (params: SyncUserPermissionsRequestInterface) : Promise<GenericResponse> => {
    const { user_id, permissions } = params;
    let requestUrl = `/api/permissions/sync_permissions_for_user`;

    const response = await apiClient.post(requestUrl, {
        permissions,
        user_id
    });
    return {
        data: response.data
    };
}

export default {
    listAllPermissionsForUser,
    listAllPermissions,
    syncPermissionsForUser
}