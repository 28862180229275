import { Autocomplete, Box, Button, Chip, Grid, TextField, Typography } from '@material-ui/core';
import { Individual } from '../@types/individual';
import { Entity } from '../@types/entity';
import { FormikErrors, FormikTouched } from 'formik'
import { curry } from 'lodash';
import React, { useEffect, useState } from 'react';
import { cursorTo } from 'readline';
import apiClient from 'services/apiClient';
import LoadingScreen from './LoadingScreen';
import { grey } from '@material-ui/core/colors';
import useDidMount from 'hooks/useDidMount';

interface ClientSelectorProps {
	disabled?: boolean
	autoLoad?: boolean
	filters? :string
	text?: string
	onClientSelect: (val: any)=>void
	onClear: () => void
	individualId?: string
	entityId?: string
}

const ClientSelector = (props: ClientSelectorProps) => {
	const {
		disabled,
		autoLoad,
		filters,
		onClear,
		onClientSelect,
		individualId,
		entityId,
		text = 'Client Selector'
	} = props;
	
	let component;
	if(!individualId && !entityId){
		component = <ClientSelectView
			disabled={disabled}
			autoLoad={autoLoad}
			filters={filters}
			text={text}
			onClientSelect={onClientSelect}
		/>;
	} else {
		component = <ClientSelectedView
			disabled={disabled}
			text={text}
			onClear={onClear}
			individualId = {individualId || '0'}
			entityId = {entityId || '0'}
		/>
	}

	return <Box padding={2} sx={{ border: 1, borderColor: grey[300],  borderRadius: '7px' }}>
		{component}
	</Box>
}

interface ClientSelectorState {
	individuals?: Array<Individual>
	entities?: Array<Entity>
	loading: boolean
	selectedInd?: string
	selectedEntity?: string
	indTextFilter?: string
	entityTextFilter?: string
}

interface ClientSelectedProps {
	disabled?: boolean
	onClear: () => void
	text?: string
	individualId: string
	entityId: string
}

interface ClientSelectedViewState {
	loading: boolean
	record?: Individual | Entity | any
}
const ClientSelectedView = (props: ClientSelectedProps) => {
	const [state, setState] = useState<ClientSelectedViewState>({loading: false, record: undefined})
	const { individualId, entityId, text, onClear, disabled} = props;
	let { record, loading } = state;

	if(!record && !loading){
		setState(curr=>({...curr, loading: true}));
		let type = '', id = '0';
		if(entityId !== '0'){
			id = entityId;
			type = 'entities';
		} else if (individualId !== '0'){
			id = individualId;
			type = 'individuals';
		}
		apiClient.get(`/api/${type}/${id}`).then(res => {
			record = res.data.data;
			setState({loading: false, record});
		}).catch(err => {
			alert("Error: Could not fetch record data.")
			setState({loading: false, record: {}});
		});

		return <LoadingScreen/>
	}

	if(loading){
		return <LoadingScreen/>
	}
	
	return <Grid container spacing={2} alignItems="center">
		<Grid item sm={6}>
			<Typography variant='subtitle1'>{text}</Typography>
			{
				individualId != '0'
				&& <TextField fullWidth disabled value={`${record.first_name} ${record.last_name}`}/>
			}
			{
				entityId != '0'
				&& <TextField fullWidth disabled value={record.name}/>
			}
		</Grid>
		<Grid item alignItems="center" justifyContent="center">
			{!disabled && <Button color='error' onClick={onClear}>Clear</Button>}
			</Grid>
	</Grid>
}

interface ClientSelectProps {
	disabled?: boolean
	autoLoad?: boolean
	filters?: string
	text?: string
	onClientSelect: (val: any)=>void
}

const ClientSelectView = (props: ClientSelectProps) => {
	const defaults: ClientSelectorState = {
		entities: undefined,
		individuals: undefined,
		loading: false,
		indTextFilter: '',
		entityTextFilter: '',
		selectedInd: undefined,
		selectedEntity: undefined
	}
	const [state, setState] = useState(defaults);
	const didMount = useDidMount();
	const { onClientSelect, text, filters = '', autoLoad = true, disabled } = props;
	let {
		loading,
		entities = !autoLoad ? [] : undefined,
		individuals = !autoLoad ? [] : undefined,
		indTextFilter,
		entityTextFilter
	} = state;

	useEffect(() => {
		fetchData(autoLoad)
	}, [filters])

	const fetchData = (force: boolean) => {
		const promises = [];
		setState((curr) => ({...curr, loading: true}));

		const baseFilter = "?limit=20";
		if(!individuals || force){
			const iFil = indTextFilter !== '' ? '&text='+indTextFilter : '&is_client=1'+filters;
			promises.push(apiClient.get('/api/individuals'+baseFilter+iFil).then(res => {
				individuals = res.data.data.data;
			}));
		}
		if(!entities || force){
			const iFil = entityTextFilter !== '' ? '&text='+entityTextFilter : '&is_client=1'+filters;
			promises.push(apiClient.get('/api/entities'+baseFilter+iFil).then(res => {
				entities = res.data.data.data;
			}));
		}

		Promise.all(promises).then(res => {
			setState((curr) => ({...curr, entities, individuals, loading: false}));
		})
		.catch(err => {
			alert("Error: Could not fetch ind and entity data. Please try again."+err)
			setState((curr) => ({...curr, entities: [], individuals: [], loading: false}));
		});
	}

	if(!loading && (!entities || !individuals)){
		fetchData(false);
		return <LoadingScreen/>
	}

	if(loading){
		return <LoadingScreen/>
	}

	return <Grid container spacing={2}>
		<Grid item sm={6}>
			<Typography variant='subtitle1'>{text}</Typography>
		</Grid>
		<Grid item sm={6}>
			<Typography fontSize={13} color={grey[600]} textAlign={'right'}>Use "Enter" to search for more results</Typography>
		</Grid>
		<Grid item sm={6}>
			<Autocomplete
				disabled={disabled}
				key='individuals-autocomplete'
				onKeyDown={(event)=>{
					if (event.key === 'Enter') {
						return setState(curr=>({...curr, individuals: undefined}))
					}
				}}
			 	renderInput={(params) => <TextField
					value={indTextFilter}
					{...params}
					autoFocus={!!indTextFilter}
					label="Individuals"
				/>}
				fullWidth
				onChange={(e, ind: any)=>{
					setState(curr=>({...curr, entityTextFilter: ""}))
					onClientSelect({entityId: 0, indId: ind.id});
				}}
				options={individuals!.map((a) => ({label: `${a.first_name} ${a.last_name}`, id: a.id}))}
				onInputChange={(event, newInputValue) => {
					setState(curr=>({...curr, indTextFilter: newInputValue}))
				}}
			/>
			{
				indTextFilter &&
				<Chip
					disabled={disabled}
					label={indTextFilter}
					onDelete={()=>{
						setState(curr=>({
							...curr,
							newInputValue: '',
							individuals: undefined
						}))}
					}
				/>
			}
		</Grid>
		<Grid item sm={6}>
			<Autocomplete
				key='entities-autocomplete'
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						return setState(curr=>({...curr, entities: undefined}))
					}
				}}
			 	renderInput={(params) => <TextField
					{...params}
					autoFocus={!!entityTextFilter}
					label="Entities"
				/>}
				fullWidth
				onChange={(e, entity: any)=>{
					setState(curr=>({...curr, indTextFilter: ""}))
					onClientSelect({entityId: entity.id, indId: 0});
				}}
				options={entities!.map((a) => ({label: a.name, id: a.id}))}
				onInputChange={(event, newInputValue) => {
					setState(curr=>({...curr, entityTextFilter: newInputValue}))
				}}
			/>
			{
				entityTextFilter &&
				<Chip
					label={entityTextFilter}
					onDelete={()=>{
						setState(curr=>({
							...curr,
							entityTextFilter: '',
							entities: undefined
						}))}
					}
				/>
			}
		</Grid>
	</Grid>
}

export default ClientSelector;