import { Box, Grid, Typography } from "@material-ui/core";
import Page from "components/Page";
import { DynamicForm, Section } from "components/_dashboard/forms";
import { DynamicFormProvider, FormSectionsToContext } from "components/_dashboard/forms/form-editor/FormEditorProvider";
import { AppContextDispatcher } from "contexts/appContext";
import React, { useContext } from "react";
import { useParams } from "react-router";
import apiClient from 'services/apiClient';

const GuestAuthPage = () => {
    const [step, setStep] = React.useState(0);
    const stepMap = [
        <ValidateEmailAddress onValidated={() => setStep(1)} />,
        <ValidateOTP onValidated={
            () => {}
        } />
    ];
    const component = stepMap[step];

    return (
        <Page title="Form Request">
            <Grid container justifyContent="center" alignItems="center" height="100vh">
                <Grid item xs={6}>
                    {component}
                </Grid>
            </Grid>
        </Page>
    );
}

/**
 * Form to valid email address before proceeding
 * to form request page.
 */
interface ValidateEmailAddressProps {
    onValidated: () => void;
}
const ValidateEmailAddress = ({onValidated}: ValidateEmailAddressProps) => {
    const params = useParams();
    const hash = params['hash'];
    const formStructure: Section = {
        index: 0,
        id: 'email_verification',
        title: 'Email Verification',
        fields: [
            {
                id: 'email_address',
                title: 'Email Address',
                type: 'text',
                required: true
            }
        ]
    };

    const onSubmit = async (data: any) => {
        data.hash = hash;
        return apiClient.post('/api/onboarding/verify_email', data)
            .then(() => {
                onValidated();
            })
            .catch(error => {
                alert('The data provided is invalid. Please contact support.');
            }
        );
    }

    return <>
        <Typography gutterBottom variant="body2" align="center">Please provide the following information to confirm your identity.</Typography>
        <Box height={20} />
        <DynamicForm
            formDefinition={formStructure}
            editSwitcher={false}
            editMode={true}
            initialState={{
                email_address: ''
            }}
            onSubmit={onSubmit}
        />
    </>;
}

interface ValidateOTPProps {
    onValidated: () => void;
}
const ValidateOTP = ({onValidated}: ValidateOTPProps) => {
	const triggerEvent = useContext(AppContextDispatcher);
    const params = useParams();
    const hash = params['hash'];
    const formStructure: Section = {
        index: 0,
        id: 'otp_verification',
        title: 'OTP Verification',
        fields: [
            {
                id: 'otp_code',
                title: 'OTP Code',
                type: 'text',
                required: true
            }
        ]
    };

    const onSubmit = async (data: any) => {
        data.hash = hash;
        return apiClient.post('/api/onboarding/verify_otp', data)
            .then((response) => {
				triggerEvent({ event: 'guest-login', params: { token: response.data.token } });
                onValidated();
            })
            .catch(error => {
                alert('The data provided is invalid. Please contact support.');
            }
        );
    }

    return <>
        <Typography gutterBottom variant="body2" align="center">Please check your inbox for the one time access code required.</Typography>
        <Box height={20} />
        <DynamicForm
            formDefinition={formStructure}
            editSwitcher={false}
            editMode={true}
            initialState={{
                otp_code: ''
            }}
            onSubmit={onSubmit}
        />
    </>;
}

export default GuestAuthPage;