import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Link, Container, Typography } from '@material-ui/core';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import apiClient from 'services/apiClient';
import { useContext } from 'react';
import { AppContextDispatcher } from 'contexts/appContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode() {
  const resendTwoFA = () => {
    apiClient.post('/api/user/resend_2fa')
      .then(res => {
        alert('Code has been resent to your email.');
      });
  }
	const triggerEvent = useContext(AppContextDispatcher);

  return (
    <RootStyle title="Verify | Minimal UI">
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Button
            size="small"
            onClick={
              () => {
                triggerEvent({ event: 'logout' });
              }
            }
            startIcon={<Icon icon={arrowIosBackFill} />}
            sx={{ mb: 2 }}
          >
            Back
          </Button>
        </Box>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Typography variant="h3" paragraph>
            Please check your email!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            We have emailed a 6-digit confirmation code, please enter the code in
            below box to verify your email.
          </Typography>

          <Box sx={{ mt: 5, mb: 3 }}>
            <VerifyCodeForm />
          </Box>

          <Typography variant="body2" align="center">
            Don’t have a code? &nbsp;
            <Link variant="subtitle2" underline="none" onClick={() => {resendTwoFA()}}>
              Resend code
            </Link>
          </Typography>
        </Box>
      </Container>
    </RootStyle>
  );
}
