
import React from 'react';
import {
    FormDateInput,
    FormTextInput,
    FormSelectInput,
    FormCheckboxInput,
    FormFileInput,
    FormDisplayText
} from './index';

export const InputTypes = {
    DATE: 'date',
    TEXT: 'text',
    SELECT: 'select',
    BOOLEAN: 'boolean',
    FILE: 'file',
    DISPLAY: 'display'
}

const GenericFormInput = ({field}: {field: any}) => {
    let comp: any = null;
    const key = field.id ? field.id : field.title.toLowerCase().replace(/ /g, '_');
    switch (field.type) {
        case InputTypes.DATE:
            comp = <FormDateInput key={key} {...field} />;
            break;
        case InputTypes.SELECT:
            comp = <FormSelectInput key={key} {...field} />;
            break;
        case InputTypes.BOOLEAN:
            comp = <FormCheckboxInput key={key} {...field} />;
            break;
        case InputTypes.FILE:
            comp = <FormFileInput key={key} {...field} />;
            break;
        case InputTypes.DISPLAY:
            comp = <FormDisplayText key={key} {...field} />;
            break;
        default:
            comp = <FormTextInput key={key} {...field} />;
            break;
    }

    return comp;
}

export default GenericFormInput;