import { Individual } from "../../../../@types/individual";
import apiClient from "services/apiClient";

interface IndexInterface {
    offset: number
    nextPageNumber: number
    orderBy: string
    sort: string
    text?: string
    additionalFilters?: Array<any>
}

export interface IndexResponse {
    data: any,
    total: number
}
const index  = async (params: IndexInterface) : Promise<IndexResponse> => {
    const { offset, nextPageNumber,  orderBy, sort, text, additionalFilters = []} = params;
    
    let requestUrl = `/api/individuals?limit=${offset}
        &page=${nextPageNumber == 0 ? 1 : nextPageNumber}
        &order_by=${orderBy}
        &sort=${sort}`;
    
    if (text && text != '') {
        requestUrl += `&text=${text}`;
    }

    additionalFilters?.forEach(({ name, value }) => {
        if(!value){
            value = "0";
        }
        requestUrl += `&${name}=${value}`;
    });

    const response = await apiClient.get(requestUrl);
    return {
        data: response.data.data.data,
        total: response.data.data.total
    };
};

const getIndividual = async ({id, trashed = false}: {id: string, trashed: boolean}) : Promise<Individual | undefined> =>
{
    const isNew = id === 'new';
    if(isNew) {
        return undefined;
    }
    let requestUrl = `/api/individuals/${id}`;

    if (trashed) {
        requestUrl = `/api/individuals/trashed/${id}`;
    }

    const response = await apiClient.get(requestUrl);
    return response.data.data;
}

export default {
    index,
    getIndividual
}